import { mapGetters } from 'vuex';

const dropOpen = {
  computed: {
    ...mapGetters({
      openState: 'GET_OPEN_STATE_SEARCH_DROPDOWN',
    }),
  },
  watch: {
    openState() {
      if (!this.openState) { this.isOpen = false; this.isMe = true; }
      this.isMe = (this.openState === this.isOpen);
    },
  },
  data() {
    return {
      isOpen: false,
      isMe: true,
    };
  },
  mounted() {
    const vm = this;
    this.$el.addEventListener('shown.bs.dropdown', () => {
      setTimeout(() => {
        vm.isOpen = true;
        vm.$store.commit('SET_OPEN_STATE_SEARCH_DROPDOWN', true);
      }, 5);
    });
    this.$el.addEventListener('hidden.bs.dropdown', () => {
      vm.$store.commit('SET_OPEN_STATE_SEARCH_DROPDOWN', false);
    });
  },
};

export default dropOpen;
