<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside"
    ref="datepickerDropDown">
      <strong>{{$t("sabre.search-panel.when")}}</strong>
      <span>
        <i class="fa-regular fa-calendar"></i>
      </span>
      <small>{{ dateString }}</small>
    </button>
    <div :class="dropdownClassName" aria-labelledby="dropdownMenuClickableInside">
      <search-date-tabs @close="closeDropdown"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'sabre-date-range',
  mixins: [dropdownOpenState],
  props: {
    componentName: {
      type: String,
      default: 'calendar',
    },
  },
  components: {
    SearchDateTabs: () => import('./searchDateTabs'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      calendarTypeList: 'GET_SABRE_CALENDAR_TYPE_LIST',
      calendarType: 'GET_SABRE_CALENDAR_TYPE',
      exactDateRange: 'GET_SABRE_EXACT_DATE_RANGE',
      specificDateRange: 'GET_SABRE_SPECIFIC_DATE_RANGE',
      fullMonths: 'GET_SABRE_FULL_MONTHS',
    }),
    dateString() {
      const { calendarTypeList, calendarType, exactDateRange, specificDateRange, fullMonths } = this;
      let str = '';
      if (calendarType === calendarTypeList[0]) {
        str = (exactDateRange[0] && exactDateRange[1]) ? `${this.convertDateFormat(exactDateRange[0])} - ${this.convertDateFormat(exactDateRange[1])}` : '';
      }
      if (calendarType === calendarTypeList[1]) {
        str = (specificDateRange[0] && specificDateRange[1]) ? `${this.convertDateFormat(specificDateRange[0])} - ${this.convertDateFormat(specificDateRange[1])}` : '';
      }
      if (calendarType === calendarTypeList[2]) {
        const last = fullMonths.length - 1;
        str = fullMonths.length ? `${fullMonths[last] || ''} ~ ${fullMonths[0] || ''}` : '';
      }
      return str;
    },
    dropdownClassName() {
      const { showed, device } = this;
      return `dropdown-menu compositionarea dropdown-menu-end ${showed ? 'show' : ''}${device === 'mobile' ? ' fixedbox' : ''}`;
    },
  },
  data() {
    return {
      showed: false,
    };
  },
  methods: {
    closeDropdown() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.datepickerDropDown).hide();
      this.$emit('closed', this.componentName);
      this.showed = false;
    },
    convertDateFormat(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    openDropdown() {
      this.showed = true;
    },
  },
};

</script>
